<template>
	<div class="app-container">
		<el-button type="primary" icon="el-icon-plus" style="margin: 0px 0px 0px 10px" size="small" class="fr" @click="view()">新增管理员</el-button>
		<div class="serchBox">
			<div>
				<span class="spanfont">角色</span>
				<el-select v-model="serachRole" clearable placeholder="请选择" size="small">
					<el-option v-for="item in roleArr" :key="item.roleId" :label="item.roleName" :value="item.roleId"> </el-option>
				</el-select>
			</div>
			<div>
				<span class="spanfont">手机</span>
				<el-input style="width: 80%" v-model="serachNum" placeholder="请输入手机号码" size="small"></el-input>
			</div>
			<el-button type="primary" size="small" style="margin-left: 15px" icon="el-icon-search" @click="getAdminList()">查询</el-button>
		</div>
		<el-table border fit :data="tableData" v-loading="loading" class="tableBackground">
			<el-table-column type="index" width="65" align="center" :label="$t('rights_profile.index')"></el-table-column>
			<!-- <el-table-column :label="$t('rights_profile.username')" align="center" prop="username" width="150"></el-table-column> -->
			<el-table-column :label="$t('rights_profile.nickname')" align="center" prop="nickName" width="150"></el-table-column>
			<el-table-column :label="$t('rights_profile.roleName')" align="center" prop="roleName" width="150"></el-table-column>
			<el-table-column :label="$t('rights_profile.phoneNumber')" align="center" prop="phoneNumber" width="150"></el-table-column>
			<el-table-column :label="$t('rights_profile.createtime')" align="center" prop="createTime" width="150"></el-table-column>
			<el-table-column :label="$t('rights_profile.updateTime')" align="center" prop="updateTime" width="150"></el-table-column>
			<el-table-column align="center" :label="$t('rights_profile.operate')" width="180">
				<template slot-scope="scope">
					<!-- <el-button type="text" size="small" @click="view(scope.row,0)">详情</el-button>
                <el-button type="text" size="small" @click="view(scope.row,1)">修改</el-button>
                <el-button type="text" size="small" @click="view(scope.row,2)">角色</el-button> -->
					<el-button v-show="scope.row.roleName != '超级管理员'" style="color: red" type="text" size="small" @click="Delete(scope.row)"
						>删除</el-button
					>
				</template>
			</el-table-column>
		</el-table>
		<el-pagination
			class="fr"
			style="margin: 20px 0"
			@size-change="handleSizeChange"
			@current-change="handleCurrentChange"
			:current-page="table.pageNo"
			:page-sizes="[20, 50, 100, 200, 300, 400]"
			:page-size="table.pageSize"
			layout="total, sizes, prev, pager, next, jumper"
			:total="table.totalNum"
		>
		</el-pagination>
		<el-dialog title="新增管理员" :visible.sync="dialogFormVisible" width="35%" center>
			<el-form :model="adminForm" label-width="100px" :rules="rule" ref="form" v-loading="formloading">
				<el-form-item label="管理员" prop="staffId" v-if="show">
					<el-select style="width: 86%" class="multi" v-model="adminForm.staffId" multiple filterable placeholder="请选择">
						<el-option v-for="item in staffList" :key="item.userId" :label="item.staffName" :value="item.userId" style="height: 60px">
							<div style="display: flex; margin-top: 7px">
								<div style="margin-left: 10px; line-height: normal; margin-top: 5px">
									<label>{{ item.staffName }}({{ item.phoneNumber }})</label>
								</div>
							</div>
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="角色" prop="roleId" v-if="show">
					<el-select style="width: 86%" v-model="adminForm.roleId" placeholder="请选择角色">
						<el-option
							v-show="role.roleName != '超级管理员'"
							v-for="role in roleArr"
							:key="role.roleId"
							:value="role.roleId"
							:label="role.roleName"
						></el-option>
					</el-select>
				</el-form-item>
				<!-- <el-form-item :label="$t('rights_profile.password')" prop="password" v-if="disabled==='add'">
                  <el-input v-model="admininfo.password" :disabled="disabled===true" style="width:86%" type="password" :placeholder="disabled==='edit'?$t('rights_profile.passwordedit'):$t('rights_profile.password')"></el-input>
              </el-form-item>
              <el-form-item :label="$t('rights_profile.password')"  v-if="disabled==='edit'">
                  <el-input v-model="admininfo.password" :disabled="disabled===true" style="width:86%" type="password" :placeholder="disabled==='edit'?$t('rights_profile.passwordedit'):$t('rights_profile.password')"></el-input>
              </el-form-item>
              <el-form-item :label="$t('rights_profile.confirmpassword')" v-if="disabled==='add'&&show" prop="confirmPass">
                  <el-input v-model="admininfo.confirmPass" style="width:70%" type="password" :placeholder="$t('rights_profile.confirmpassword')"></el-input>
              </el-form-item>
              <el-form-item :label="$t('rights_profile.rolename')"  v-if="!show">
                  <el-select v-model="role" multiple :disabled="disabled==true" :placeholder="$t('rights_profile.rolename')">
                    <el-option v-for="role in roleArr" :key="role.roleId" :value="role.roleId" :label="role.roleName"></el-option>
                  </el-select>
              </el-form-item> -->

				<!--<el-form-item style="text-align:center" label-width="0">
                  <el-button type="plain" @click="dialogFormVisible=false">取消</el-button>
                  <el-button type="primary" @click="submitForm('form')" :disabled="disabled==true" v-if="show">提交</el-button>
                  <el-button type="primary" @click="changeRole" :disabled="disabled==true" v-if="!show">提交</el-button>
              </el-form-item>!-->
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button type="plain" @click="dialogFormVisible = false">取消</el-button>
				<el-button type="primary" @click="submitForm('form')" :disabled="disabled == true" v-if="show">提交</el-button>
				<el-button type="primary" @click="changeRole" :disabled="disabled == true" v-if="!show">提交</el-button>
			</span>
		</el-dialog>
	</div>
</template>
<script>
import local from '../local';
import * as api from '@/api/system';
const viewName = 'admin';

export default {
	data() {
		const validatePass = (rule, value, callback) => {
			const re = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{8,20}$/;

			if (value === '') {
				callback(new Error('请输入密码'));
			} else if (!re.test(value)) {
				callback(new Error('请输入8-20位字符，必须包含字母和数字'));
			} else {
				if (this.admininfo.password !== '' && this.disabled === 'add') {
					this.$refs.form.validateField('confirmPass');
				}
				callback();
			}
		};

		const confirmPass = (rule, value, callback) => {
			if (value === '') {
				callback(new Error('请输入确认密码'));
			} else if (value !== this.admininfo.password) {
				callback(new Error('两次密码不一致'));
			} else {
				callback();
			}
		};

		return {
			tableData: [],
			show: true,
			disabled: false,
			formloading: false,
			loading: false,
			roleArr: [],
			role: [],
			serachRole: '',
			staffList: [],
			serachNum: '',
			// admininfo:{
			//     userId:"",
			//     username:"",
			//     nickName:"",
			//     password:"",
			//     confirmPass:""
			// },
			rule: {
				staffId: [{ required: true, message: '请选择用户', trigger: 'change' }],
				nickName: [{ required: true, message: '请输入昵称', trigger: 'blur' }],
				password: [{ required: true, validator: validatePass, trigger: 'blur' }],
				confirmPass: [{ required: true, validator: confirmPass, trigger: 'blur' }],
				roleId: [{ required: true, message: '请选择角色', trigger: 'change' }]
			},
			dialogFormVisible: false,
			table: {
				pageNo: 1,
				pageSize: 20,
				totalNum: 0
			},
			adminForm: {
				staffId: [],
				roleId: ''
			}
		};
	},
	methods: {
		// 获取部门人员
		getStaffList() {
			api.getStaffList({
				request: {
					pageNo: 1,
					pageSize: 9999,
					orgId: '',
					keyWork: '',
					userType: '3'
				}
			}).then((res) => {
				this.staffList = res.list;
			});
		},
		getAdminList() {
			this.loading = true;
			api.getAdminlist({
				request: {
					pageNo: this.table.pageNo,
					pageSize: this.table.pageSize,
					roleId: this.serachRole,
					phoneNumber: this.serachNum
				}
			}).then((res) => {
				if (res.list) {
					this.tableData = res.list;
					this.table.totalNum = res.totalNum;
				}
				this.loading = false;
			});
		},
		handleSizeChange(val) {
			this.table.pageSize = val;
		},
		changeRole() {
			const params = {
				request: {
					userId: this.admininfo.userId,
					roleIdList: this.role
				}
			};

			api.updateAdminRole(params).then((res) => {
				if (res.code == 0) {
					this.$message.success('修改管理员成功');
					this.dialogFormVisible = false;
					this.handleCurrentChange(this.table.pageNo);
				}
			});
		},
		submitForm(formName) {
			this.$refs[formName].validate((valid) => {
				if (valid) {
					const params = {
						request: {
							userIds: this.adminForm.staffId,
							roleId: this.adminForm.roleId
						}
					};

					api.addAdmin(params).then((res) => {
						if (res.code === 0) {
							this.$message.success('新增管理员成功');
							this.dialogFormVisible = false;
							this.$refs[formName].resetFields();
							this.handleCurrentChange(this.table.pageNo);
						}
					});
				} else {
					return false;
				}
			});
		},
		getAllrole() {
			api.getRolelist({ request: { pageNo: 1, pageSize: 500 } }).then((res) => {
				this.roleArr = res.list;
				const roleArr = [];

				this.roleArr.forEach((item) => {
					roleArr.push({ roleId: item.roleId, roleName: item.roleName });
				});
				this.roleArr = roleArr;
			});
		},
		getAdminRole(userId) {
			this.formloading = true;
			api.getAdminRole({ request: { userId } }).then((res) => {
				if (res) {
					this.role = res;
					this.formloading = false;
				}
			});
		},
		view(row, type) {
			if (row) {
				this.$nextTick(() => {
					for (const key in this.admininfo) {
						for (const key1 in row) {
							if (key === key1) {
								this.admininfo[key] = row[key1];
							}
						}
					}
				});
			}
			if (type === 0) {
				this.disabled = true;
				this.show = true;
			} else if (!type) {
				this.disabled = 'add';
				this.show = true;
				this.admininfo = {
					userId: '',
					username: '',
					nickName: '',
					password: '',
					confirmPass: ''
				};
				this.getStaffList();
				this.dialogFormVisible = true;
				return;
			} else if (type == '2') {
				this.disabled = false;
				this.show = false;
				this.getAdminRole(row.userId);
			} else {
				this.disabled = 'edit';
				this.show = true;
			}
			this.dialogFormVisible = true;
		},
		handleCurrentChange(val) {
			this.table.pageNo = val;
			this.getAdminList();
		},
		Delete(row) {
			this.$confirm('此操作将删除当前管理员，是否继续?', '提示', {
				confirmButtonText: '是',
				cancelButtonText: '否',
				type: 'warning'
			})
				.then(() => {
					api.deleteAdmin({ request: { userId: row.userId, roleId: row.roleId } }).then((res) => {
						if (res.code === 0) {
							this.$message.success('删除成功');
							this.handleCurrentChange(this.table.pageNo);
						}
					});
				})
				.catch(() => {
					this.$message({
						type: 'info',
						message: '已取消删除'
					});
				});
		}
	},
	created() {
		if (!this.$i18n.getLocaleMessage('en')[viewName]) {
			this.$i18n.mergeLocaleMessage('en', local.en);
			this.$i18n.mergeLocaleMessage('zh', local.zh);
		}
	},
	mounted() {
		this.getAdminList();
		this.getAllrole();
	}
};
</script>
<style lang="scss">
.el-select-dropdown.is-multiple .el-select-dropdown__item.selected::after {
	top: 7px;
}
</style>

<style lang="scss" scoped>
.spanfont {
	display: inline-block;
	font-size: 14px;
	color: #484848;
	height: 32px;
	line-height: 32px;
	vertical-align: top;
	margin-right: 10px;
}
.serchBox {
	margin-bottom: 20px;
	div {
		display: inline-block;
		&:first-child {
			margin-right: 20px;
		}
	}
}
</style>
