export default {

    en: {
        countrylang: {
            add: "+ Add",
            view: 'View',
            edit: 'Edit',
            delete: 'Delete',
            yes: "yes",
            no: "no",
            submit: "Submit",
            reset: "Reset",
            name: 'Name',
            timezone: 'Timezone',
            langcode: 'Lang Code',
            operate: 'Operate',

        },
        paramslang: {
            configuration: "Configuration",
            language: 'Language',
            country: 'Country',
            submit: "Submit",
        },
    },
    zh: {
        rights_profile: {
            index: "序号",
            username: "用户名",
            nickname: "昵称",
            roleName:"角色",
            phoneNumber:"手机号码",
            createtime: "新建时间",
            updateTime: "修改时间",
            operate: "操作",
            admindigtitle: "管理员修改/新增",
            password: "密码",
            passwordedit: "不输入则不修改密码",
            confirmpassword: "确认密码",
            rolename: "角色"
        },
        roleManage: {
            index: "序号",
            rolename: "角色名",
            description: "描述",
            createtime: "创建时间",
            updateTime: "修改时间",
            operate: "操作",
            roledigtitle: "角色操作",
            no: "取消",
            yes: "确定",
            permissiondigtitle: "权限操作"
        }
    }
}